<template>
  <main>
    <package-header title="Withdraw" sub-title="12764 Smith"></package-header>

    <div class="container-fluid">

      <div class="mb-3 border-bottom pb-3">
        <!-- Back to applications link -->
        <router-link class="mr-2" to="/pages/applications">
          <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
          <span class="d-none d-sm-inline-block">Return to Applications</span>
          <span class="d-sm-none">Back</span>
        </router-link>
      </div>

      <div class="row">

        <div class="col-lg-8">

          <h2 class="mb-3">1 - Select application to withdraw</h2>

          <div class="sticky-table mb-4">
            <table class="table table-hover table-stacked table-select-row bg-white">
              <thead>
                <tr>
                  <th scope="row">
                    <div class="custom-control custom-checkbox">
                      <input id="th" type="checkbox" class="custom-control-input">
                      <label class="custom-control-label" for="th">&nbsp;</label>
                    </div>
                  </th>
                  <th>Application No.</th>
                  <th>Application Status</th>
                  <th scope="col">Name</th>
                  <th scope="col">Transaction Type</th>
                  <th scope="col">Document No</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in tableItems">
                  <td scope="row">
                    <div class="custom-control custom-checkbox">
                      <input :id="index" type="checkbox" class="custom-control-input">
                      <label class="custom-control-label" :for="index">&nbsp;</label>
                    </div>
                  </td>
                  <td data-header="Application No.">{{ item.a }}</td>
                  <td data-header="Application Status">{{ item.b }}</td>
                  <td data-header="Name">{{ item.c }}</td>
                  <td data-header="Transaction Type">{{ item.d }}</td>
                  <td data-header="Document No">{{ item.d }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <hr>

          <h2 class="mt-4">2 - Select your authorization method</h2>

          <div class="form-group mt-3">
            <div class="custom-control custom-radio">
              <input type="radio" id="authorizationMethod1" name="authorizationMethod" class="custom-control-input" v-model="authorizationMethod" value="1">
              <label class="custom-control-label" for="authorizationMethod1">
                I am the applicant, or I am the Solicitor or Notary Public, or an employee of the Solicitor or Notary Public who is acting on behalf of the applicant(s) for the applications being withdrawn and I'm authorized to make this request.
              </label>
            </div>
            <div class="custom-control custom-radio">
              <input type="radio" id="authorizationMethod2" name="authorizationMethod" class="custom-control-input" v-model="authorizationMethod" value="2">
              <label class="custom-control-label" for="authorizationMethod2">
                I have an authorization letter to add to my withdraw request
              </label>
            </div>
          </div>

          <div class="form-group mb-5" v-if="authorizationMethod === '2'">
            <label for="attachment">Attach letter of authorization</label>
            <input type="file" class="form-control" id="attachment">
          </div>

          <div class="mt-4">
            <button class="btn btn-danger mr-1">Request Withdrawal</button>
            <button class="btn btn-link mr-1">Cancel</button>
          </div>

        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Package Management"></help-panel>
  </main>
</template>

<script>

import PackageHeader from '../../components/PackageHeader.vue'
import PackageNavigation from '../../components/PackageNavigation.vue'
import HelpPanel from '../../components/HelpPanel.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel
  },

  data () {
    return {
      authorizationMethod: '',
      tableItems: [
        {
          a:'CA748827',
          b:'Pending',
          c:'Transfer Caso',
          d:'Freehold Transfer',
          e:'CA748827',
        },
        {
          a:'CA748828',
          b:'Pending',
          c:'A1 release',
          d:'Release',
          e:'CA748828',
        },
        {
          a:'CA748829',
          b:'Pending',
          c:'A1 release',
          d:'Release',
          e:'CA748828',
        },
        {
          a:'CA748830',
          b:'Pending',
          c:'A1 release',
          d:'Release',
          e:'CA748828',
        },
        {
          a:'CA748831',
          b:'Pending',
          c:'A1 release',
          d:'Release',
          e:'CA748828',
        },
        {
          a:'CA748832',
          b:'Pending',
          c:'Mortgage Caso',
          d:'Mortgage',
          e:'CA748832',
        }
      ]
    }
  },
  methods: {
    checkbox: function($event) {
      var _this = this;

      if (event.target.checked) {
        _this.checked ++
      } else {
        _this.checked --
      }
    }
  }
}
</script>

